import React from 'react';
import { IoIosArrowRoundForward } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import img1 from '../../../../assets/reportIcon2.svg';
import  './SimilarResources.scss'
const SimilarResources = ({header}) => {

    const data=  [
        {
            icon:img1,
            header:"Report finds more than 200 projects could benefit from subsea boosting",
            tags:"Renewables & Power",
            date:"May 12, 2024"
        },
        {
            icon:img1,
            header:"Report finds more than 200 projects could benefit from subsea boosting",
            tags:"Renewables & Power",
             date:"May 12, 2024"
        },
        {
            icon:img1,
            header:"Report finds more than 200 projects could benefit from subsea boosting",
            tags:"Renewables & Power",
             date:"May 12, 2024"
        },
        {
            icon:img1,
            header:"Report finds more than 200 projects could benefit from subsea boosting",
            tags:"Renewables & Power",
             date:"May 12, 2024"
        },
        {
            icon:img1,
            header:"Report finds more than 200 projects could benefit from subsea boosting",
            tags:"Renewables & Power",
             date:"May 12, 2024"
        },
        {
            icon:img1,
            header:"Report finds more than 200 projects could benefit from subsea boosting",
            tags:"Renewables & Power",
             date:"May 12, 2024"
        }
    ]


  return (
    
    <div className='similar-resource-main'>
      <div className='similar-resource-body' >

        <div className='similar-resource-header'>
            <h4>{header}</h4>
            <div>
                <h4>See all</h4>
                <IoIosArrowRoundForward
                size={30}
                color=''
                />
            </div>
        </div>
        {data.map((item,index)=>(
        <div key={index} className='similar-resource-content'>
           
                
            <div  className='similar-resource-img'>
                <div>
                     <img src={item.icon} alt="" />
                </div>
                
                 <div>
                     <h4>{item.header}</h4>
                 </div>
            </div>

            <div>
                <li>{item.tags}</li>
            </div>

            <div>
                <p>{item.date}</p>
            </div>
 
                <IoIosArrowForward
                size={20}
                color='#9CA0A5'
                />
                
        </div>
        ))}

      </div>
    </div>
  
  )
}

export default SimilarResources
