import React from 'react';
import './ReportContentView.scss';
import { BsDownload } from "react-icons/bs";
import { AiOutlineLink } from "react-icons/ai";
import bookmark from '../../../../assets/repBookmarkIcon.png'
import { LuClock3 } from "react-icons/lu";
import { LuCalendarPlus } from "react-icons/lu";
import { VscEye } from "react-icons/vsc";

const ReportContentView = ({reportTitle}) => {

  const viewData = [
    {
        subheader:"Title",
        para:"Solar and meteorological ground measurements from Senegal"
    },
    {
        subheader:"Short description",
        para:"Data repository for solar and meteorological ground measurements from a network of weather stations in West Africa. "
    },
    {
        subheader:"Long description",
        para:"Data repository for solar and meteorological ground measurements from a network of weather stations in West Africa. The data is provided in the framework of the West African Power Pool. The data is provided in the framework of the West African Power Pool. The data is provided in the framework of the West African Power Pool."
    },
    {
        subheader:"Industry",
        para:'Oil and Gas'
    },
    {
        subheader:"Tags",
        para:"Tag 1, Tag 2, Tag 3"
    },
    {
        subheader:"Source",
        para:"Social Media, Company Reports"
    }
  ] 

  return (

    <div className='report-view-cont'>

      <div className='report-view-header'>

        <div style={{width:'50%'}}>
            <h2>{reportTitle}</h2>
        </div>

        <div className='report-view-btn-cont'>

            <div className='report-view-btn-one'>

                <BsDownload
                    size={20}
                />
                <h4> Download(PDF)</h4>

            </div>

            <div className='report-view-btn-one'>

                <AiOutlineLink
                    size={20}
                />
                <h4> Copy Link </h4>
            </div>

            <div>
                <button
                onClick={()=>window.location.href="/documents/home/viewdocument/pdf"}
                className='report-view-btn-two'
                > Read Document</button>
            </div>
           
           
        </div>

    </div>
      
      <div className='report-view-content'>

        <div className='report-view-content-one'>

            {viewData.map((item,index)=> (

                 <div  className = "report-view-details" key={index}>
                    <h4>{item.subheader}</h4>
                    <p>{item.para}</p>
                 </div>

            ))}
           

        </div>

        <div className='report-view-content-two'>

            <div className='report-view-content-line'>
                <div>
                    <LuCalendarPlus
                    color='#4B4F57'
                    />
                    <h4>Date Created</h4>
                </div>

                <p>June 22, 2024</p>

            </div>

            <div className='report-view-content-line'>
                <div>
                    <LuClock3
                    color='#4B4F57'
                    />
                    <h4>Last Updated</h4>
                </div>

                <p>June 22, 2024</p>

            </div>

            <div className='report-right-divider'>

            </div>

            <div className='report-view-content-line'>

                <div>
                    <BsDownload
                    color='#4B4F57'
                    />
                    <h4>Downloads</h4>
                </div>

                <p>239</p>

            </div>

            <div className='report-view-content-line'>
                <div>
                    <VscEye
                    color='#4B4F57'
                    />
                    <h4>Views</h4>
                </div>

                <p>239</p>

            </div>

            <div className='report-bookmark'>

                <div>
                    <img src={bookmark} alt="icon" />
                   
                </div>
                <h4>Save to bookmarks</h4>

            </div>
            
        </div>

      </div>

    </div>
  )
}

export default ReportContentView
