import React from 'react';
import img1 from "../../../../assets/standards.png";
import img2 from '../../../../assets/analysis.png';
import img3 from '../../../../assets/regulations.png';
import img4 from '../../../../assets/guidelines.png';
import img5 from '../../../../assets/research.png';
import img6 from '../../../../assets/periodicals.png';
import bkmk from '../../../../assets/bookmarks.svg'
import shareIcon from '../../../../assets/share.png';
import { IoLocationOutline } from "react-icons/io5";
import { IoLanguage } from "react-icons/io5";
import { FaHashtag } from "react-icons/fa6";
import { CiCalendar } from "react-icons/ci";
import eyeImg from '../../../../assets/eyeIcon.png';
import './ReportsCard.scss';

const ReportsCard = () => {

    const oilAndGasData = [
        {
          icon:img1,
          type:"STANDARDS",
          title: "2014 Oil and Gas Industry Annual Report",
          organization: "Department of Petroleum Resources, 2014",
          description:
            "The Department of Petroleum Resources (now Nigerian Upstream Petroleum Regulatory Commission NUPRC) provides insights into key operations in the Nigerian oil and gas industry for the year in review - 2014. This includes upstream data (e.g. production, reserves and export), downstream data...",
          location: "Nigeria",
          language: "English",
          sectors: ["Oil & Gas", "Downstream", "Upstream"],
          date: "May 20, 2024",
          views: 200,
        },
        {
          icon:img2,
          type:"ANALYSIS",
          title: "Nigerian Oil and Gas Industry Content Development Act",
          organization: "Nigerian Content Development and Monitoring Board, 2010",
          description:
            "An act to provide for the development of local content in the Nigerian oil and gas industry. This includes provisions guiding regulators and operators on oil & gas licensing, contract awards, staffing, host community engagement etc.",
          location: "Nigeria",
          language: "English",
          sectors: ["Oil & Gas", "Downstream", "Upstream"],
          date: "May 20, 2024",
          views: 200,
        },
        {
          icon:img3,
          type:"REGULATIONS",
          title: "2014 Oil and Gas Industry Annual Report",
          organization: "Department of Petroleum Resources, 2014",
          description:
            "The Department of Petroleum Resources (now Nigerian Upstream Petroleum Regulatory Commission NUPRC) provides insights into key operations in the Nigerian oil and gas industry for the year in review - 2014. This includes upstream data (e.g. production, reserves and export), downstream data...",
          location: "Nigeria",
          language: "English",
          sectors: ["Oil & Gas", "Downstream", "Upstream"],
          date: "May 20, 2024",
          views: 200,
        },
        {
          icon:img4,
          type:"RESEARCH",
          title: "Nigerian Oil and Gas Industry Content Development Act",
          organization: "Nigerian Content Development and Monitoring Board, 2010",
          description:
            "An act to provide for the development of local content in the Nigerian oil and gas industry. This includes provisions guiding regulators and operators on oil & gas licensing, contract awards, staffing, host community engagement etc.",
          location: "Nigeria",
          language: "English",
          sectors: ["Oil & Gas", "Downstream", "Upstream"],
          date: "May 20, 2024",
          views: 200,
        },
        {
          icon:img5,
          type:"PERIODICALS",
          title: "2014 Oil and Gas Industry Annual Report",
          organization: "Department of Petroleum Resources, 2014",
          description:
            "The Department of Petroleum Resources (now Nigerian Upstream Petroleum Regulatory Commission NUPRC) provides insights into key operations in the Nigerian oil and gas industry for the year in review - 2014. This includes upstream data (e.g. production, reserves and export), downstream data...",
          location: "Nigeria",
          language: "English",
          sectors: ["Oil & Gas", "Downstream", "Upstream"],
          date: "May 20, 2024",
          views: 200,
        },
        {
          icon:img6,
          type:"OTHERS",
          title: "Nigerian Oil and Gas Industry Content Development Act",
          organization: "Nigerian Content Development and Monitoring Board, 2010",
          description:
            "An act to provide for the development of local content in the Nigerian oil and gas industry. This includes provisions guiding regulators and operators on oil & gas licensing, contract awards, staffing, host community engagement etc.",
          location: "Nigeria",
          language: "English",
          sectors: ["Oil & Gas", "Downstream", "Upstream"],
          date: "May 20, 2024",
          views: 200,
        },
        // Add other objects similarly
      ];
      
  return (
    <section className='reports_main'>

    {oilAndGasData.map((item,index)=>(

      <div 
      onClick={()=>window.location.href=`/documents/home/viewdocument/${item.title}`}
      className='reports_content_cont' key={index} >


        <div>
            <img src={item.icon} alt="icon" />
        </div>

        <div className='reports_content_main'>


            <div className='reports_title_cont'>
                <h5>{item.type}</h5>

                <div>
                    <img src={shareIcon} alt="" />
                    <img src={bkmk} alt="icon" />
                </div>
            </div>

            <h4>{item.title}</h4>

            <h5>{item.organization}</h5>

            <p>{item.description}</p>

            <div className='reports_add_info'>

                <div className='reports_tags'>
                    <div>
                        <IoLocationOutline
                        color='#9CA0A5'
                        />
                        <h4>{item.location}</h4>
                    </div>
                    <div>
                        <IoLanguage
                        color='#9CA0A5'
                        />
                        <h4>{item.language}</h4>
                    </div>
                    <div>
                        <FaHashtag
                        color='#9CA0A5'
                        />
                        <h4>{item.sectors}</h4>
                    </div>
                    <div>
                       <CiCalendar
                       color='#9CA0A5'
                       />
                        <h4>{item.date}</h4>
                    </div>
                </div>

                <div className='reports_view'>
                    <img src={eyeImg} alt="" />
                    <h4>{item.views}</h4>
                </div>
               
            </div>


        </div>
       
      </div>
      

    ))}

    </section>
  )
}

export default ReportsCard
