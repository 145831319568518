import React from 'react'
import ConsumerSidebar from '../Reusable/ConsumerSidebar/ConsumerSidebar'
import Navbar from '../Reusable/Navbar/Navbar'
import NewsPageHeader from '../Reusable/NewsPageHeader/NewsPageHeader'
import ReportsCard from './ReportsCard/ReportsCard'
import PaginationReport from '../Reusable/Pagination/ReportPagination'

const ConsumerReport = () => {
  return (
    <div className='dashboard-main'>
      <ConsumerSidebar/>
      <section>
        <Navbar/>
        <NewsPageHeader
          newsHeader={"Document"} 
          searchName={"report"}
        />
        <ReportsCard />
        <PaginationReport />
      </section>
    </div>
  )
}

export default ConsumerReport
