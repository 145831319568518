import React,{useState,useEffect} from "react";
import { Navigate, BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SignUp from './components/Onboarding/SignUp/SignUp';
import { useSelector } from "react-redux";
import EmailVerification from './components/Onboarding/EmailVerification/EmailVerification';
import MoreAboutYou from './components/Onboarding/MoreAboutYou/MoreAboutYou';
import Login from './components/Authentication/Login/Login';
import ForgotPass from './components/Authentication/ForgotPass/ForgotPass';
import ResetPass from './components/Authentication/ResetPass/ResetPass';
import DiscoverConsumer from './components/ConsumerDashboard/DiscoverConsumer/DiscoverConsumer';
import ConsumerNews from './components/ConsumerDashboard/ConsumerNews/ConsumerNews';
import ConsumerData from './components/ConsumerDashboard/ConsumerData/ConsumerData';
import ConsumerDataVisual from './components/ConsumerDashboard/ConsumerDataVisual/ConsumerDataVisual';
import ConsumerReport from './components/ConsumerDashboard/ConsumerReport/ConsumerReport';
import ConsumerProfile from './components/ConsumerDashboard/ConsumerProfile/ConsumerProfile';
import ConsumerWallet from './components/ConsumerDashboard/ConsumerWallet/ConsumerWallet';
import ScrollToTop from './Scroll';
import Redirect from './components/Redirect';
import ContributorSidebar from "./components/ContributorDashoard/Reusable/ContributorSidebar/ContributorSidebar";
import DiscoverContributor from "./components/ContributorDashoard/DiscoverContributor/DiscoverContributor";
import ConsumerNewsBookmark from "./components/ConsumerDashboard/ConsumerNews/ConsumerBookMark";
import ReportsPage from "./components/ConsumerDashboard/ConsumerReport/ReportsPage/ReportsPage";
import ViewReport from "./components/ConsumerDashboard/ConsumerReport/ViewReport/ViewReport";

function App() {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    return userInfo && Object.keys(userInfo).length > 0;
  });
  
  
  const [role, setRole] = useState(null);
  const [verified,setVerified]=useState(null)
  const userData = useSelector((state) => state.userLogin.userInfo.user);
  
  useEffect(() => {
    const handleStorageChange = () => {
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      setIsLoggedIn(userInfo && Object.keys(userInfo).length > 0);
    };

    window.addEventListener('storage', handleStorageChange);

    // Cleanup
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    
    
    if (userInfo) {
      const accountType = userData.account_type;
      const accountVerified = userData.is_verified;
      const accountStatus = userData.user_status;
      if (accountType === "consumer" && accountVerified === true && accountStatus === "active") {
        setRole('consumer');
        setVerified(true);
      } else if (accountType === "contributor" && accountVerified === true && accountStatus === "active") {
        setRole('contributor');
        setVerified(true);
      } else if (accountType === "consumer_contributor" && accountVerified === true && accountStatus === "active") {
        setRole('hybrid');
        setVerified(true);
      } else if (accountType === null && accountVerified === true && accountStatus === "onboarding") {
        setRole('onboard');
        setVerified(true);
      } 
       else if (accountType === null && accountVerified === false && accountStatus === "onboarding") {
        setRole('onboard');
        setVerified(false);

      } else {
        setRole(null);
       
      }
    } else {
      setRole(null);
    }
  }, [userData,userInfo]);


  
  return (
    <Router>
       <ScrollToTop/>
      <Routes>
      { role === 'consumer' && (
        <>
         <Route path="*" element={<Navigate to="/consumer/dashboard" />} />
        <Route path="/" element={<Navigate to="/consumer/dashboard"/>} />
        <Route path="/forgotpassword" element={<ForgotPass />} />
        <Route path="/resetPassword" element={<ResetPass />} />
        <Route path="/consumer/dashboard" element={<DiscoverConsumer />} />
        <Route path="/consumer/news/all" element={<ConsumerNews />} />
        <Route path="/dataset-dsb" element={<ConsumerData />} />
        <Route path="/data/vis-dsb" element={<ConsumerDataVisual />} />
        <Route path="/documents/home" element={<ConsumerReport />} />
        <Route path="/documents/home/viewdocument/:reportname" element={<ReportsPage />} />
        <Route path="/documents/home/viewdocument/pdf" element={<ViewReport />} />
        <Route path="/consumer/profile" element={<ConsumerProfile />} />
        <Route path="/consumer/wallet" element={<ConsumerWallet />} />
        <Route path="/getstarted/flow-1" element={<MoreAboutYou />} />
        <Route path="/consumer/news/all/bookmarks" element={<ConsumerNewsBookmark />} />
        </>

      )}
      {  role === 'contributor' && (
        <>
         <Route path="*" element={<Navigate to="/contributor/dashboard" />} />
        <Route path="/" element={<ContributorSidebar />} />
        <Route path="/forgotpassword" element={<ForgotPass />} />
        <Route path="/resetPassword" element={<ResetPass />} />
        <Route path="/getstarted/flow-1" element={<MoreAboutYou />} />
        <Route path="/contributor/dashboard" element={<DiscoverContributor />} />
        <Route path="/contributor/news/all" element={<ConsumerNews type={"contri"} />} />
        <Route path="/contributor/news/all/bookmarks" element={<ConsumerNewsBookmark type={"contri"}/>} />
        </>

      )}
      { role === 'hybrid' && (
        <>
       
        <Route path="/" element={<Navigate to="/contributor/dashboard" />} />
        <Route path="/forgotpassword" element={<ForgotPass />} />
        <Route path="/resetPassword" element={<ResetPass />} />
        <Route path="/consumer/dashboard" element={<DiscoverConsumer />} />
        <Route path="/consumer/news/all" element={<ConsumerNews />} />
        <Route path="/contributor/news/all" element={<ConsumerNews type={"contri"}/>} />
        <Route path="/consumer/news/all/bookmarks" element={<ConsumerNewsBookmark />} />
        <Route path="/contributor/news/all/bookmarks" element={<ConsumerNewsBookmark type={"contri"}/>} />
        <Route path="/dataset-dsb" element={<ConsumerData />} />
        <Route path="/data/vis-dsb" element={<ConsumerDataVisual />} />
        <Route path="/documents/home" element={<ConsumerReport />} />
        <Route path="/documents/home/viewdocument/:reportname" element={<ReportsPage />} />
        <Route path="/documents/home/viewdocument/pdf" element={<ViewReport />} />
        <Route path="/consumer/profile" element={<ConsumerProfile />} />
        <Route path="/consumer/wallet" element={<ConsumerWallet />} />
        <Route path="/getstarted/flow-1" element={<MoreAboutYou />} />
        <Route path="/contributor/dashboard" element={<DiscoverContributor />} />
        <Route path="*" element={<Navigate to="/contributor/dashboard" />} />
        </>

      )}
      {(role === 'onboard') && (verified === true) &&(
        <>
         <Route path="*" element={<Navigate to="/getstarted/flow-1" />} />
         <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgotpassword" element={<ForgotPass />} />
        <Route path="/resetPassword" element={<ResetPass />} />
        <Route path="/getstarted/flow-1" element={<MoreAboutYou />} />
        <Route path="/verifymail" element={<EmailVerification />} />
        </>

      )}
      {(role === 'onboard') && (verified === false) &&(
        <>
         <Route path="*" element={<Navigate to="/verifymail" />} />
         <Route path="/" element={<Navigate to="/verifymail" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgotpassword" element={<ForgotPass />} />
        <Route path="/resetPassword" element={<ResetPass />} />
        <Route path="/verifymail" element={<EmailVerification />} />
        </>

      )}
      {!isLoggedIn && (
        <>
        <Route path="*" element={<Navigate to="/Login" />} />
         <Route path="/" element={<Redirect />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgotpassword" element={<ForgotPass />} />
          <Route path="/resetPassword" element={<ResetPass />} />
          <Route path="/login" element={<Login />} /> 
        </>
      )}
        {/* <Route path="/" element={<Redirect />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/verifymail" element={<EmailVerification />} />
        <Route path="/getstarted/flow-1" element={<MoreAboutYou />} />
        <Route path="/forgotpassword" element={<ForgotPass />} />
        <Route path="/resetPassword" element={<ResetPass />} />
        <Route path="/consumer/dashboard" element={<DiscoverConsumer />} />
        <Route path="/news-dsb" element={<ConsumerNews />} />
        <Route path="/dataset-dsb" element={<ConsumerData />} />
        <Route path="/data/vis-dsb" element={<ConsumerDataVisual />} />
        <Route path="/documents-dsb" element={<ConsumerReport />} />
        <Route path="/consumer/profile" element={<ConsumerProfile />} />
        <Route path="/consumer/wallet" element={<ConsumerWallet />} />
        <Route path="/login" element={<Login />} /> */}

      </Routes>
  </Router>
  );
}

export default App;
