import React from 'react';
import './PdfViewer.scss';
import report from '../../../../assets/reportpdf.png';
import { PiWarningBold } from "react-icons/pi";

const PdfViewer = () => {
    return (

        <div className='pdf-backgd'>

            <div className='pdf-main-cont'>

                <div className='pdf-cont-header'>

                    <div className='pdf-header-left'>

                        <div>
                         <img src={report} alt="" />
                        </div>

                        <div>
                            <h4>World Energy Investment report 2024</h4>
                            <p className='pdf-left-tag'>
                                <li>Oil and Gas</li>
                                <p>Last Updated: June 20, 2024</p>
                            </p>
                        </div>

                    </div>

                    <div className='pdf-header-btn'>

                        <div >
                            <h4
                            onClick={()=>window.location.href="/documents/home/viewdocument/Solar and meteorological ground measurements from Senegal"}
                            className='pdf-header-close-btn'
                            >Close Preview</h4>
                        </div>

                        <div className='pdf-header-report-btn'>
                            <PiWarningBold
                            color='#D42620'
                            fontWeight={"600"}
                            />
                            <h4>Document</h4>
                        </div>
                    </div>

                </div>

                <div className='pdf-iframe-cont'>
                   

                   
                     <iframe
                    src={"https://www.africau.edu/images/default/sample.pdf"}
                    title="PDF Viewer"
                    style={{ width: '60%', height: '100vh', border: 'none',background:"#F6F6F6",padding:'40px' }}
                     >

                    </iframe>
                    
                </div>

                    
            </div>
     
        </div>
    );
};

export default PdfViewer;
