import React, { useState,useEffect } from 'react'
import ConsumerSidebar from '../Reusable/ConsumerSidebar/ConsumerSidebar'
import Navbar from '../Reusable/Navbar/Navbar'
import Banner from '../Reusable/Banner/Banner'
import GreetingBox from '../Reusable/GreetingBox/GreetingBox'
import JumpBackIn from './JumpBackIn/JumpBackIn'
import Trending from './Trending/Trending'
import SeeAllBox from '../Reusable/SeeAllBox/SeeAllBox'
import NewsSeeAll from '../Reusable/NewsSeeAll/NewsSeeAll'
import Upgrade from '../Reusable/Upgrade/Upgrade'
import DashboardLoader from '../Reusable/DashboardLoader/DashboardLoader'

const DiscoverConsumer = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // 2000 milliseconds = 2 seconds

    return () => clearTimeout(timer); // Cleanup the timeout on unmount
  }, []);

  return (
    <>
    {loading ? (

      <div>
      <DashboardLoader/>
      </div>
    ):(

    
   
    <div className='dashboard-main'>
     
      <div>
      <ConsumerSidebar/>
      </div>
      
      <section >

        <Navbar/>
        <Banner/>
        <GreetingBox/>
        <JumpBackIn/>
        <Trending/>
        <div style={{display:'flex',padding:'0em 2em 0em 2.5em',gap:'24px'}}>
          <div>
          <SeeAllBox
            headerTitle={"Data"}
            imageHeight={"144px"}
            imageWidth={"144px"}
            sliceNo={4}
            contHeight={"325px"}
          
            paddingMain={"0em 0em 1.5em 0em"}
          />
          <SeeAllBox
            headerTitle={"Infographics"}
            imageHeight={"130px"}
            imageWidth={"200px"}
            sliceNo={3}
            contHeight={"289px"}
          
            paddingMain={"0em 0em 1.5em 0em"}
          />
          </div>

          <NewsSeeAll
          sliceNo={6}
          contHeight={"685px"}
          />
        </div>

        <SeeAllBox
            headerTitle={"Industry Insights"}
            imageHeight={"220px"}
            imageWidth={"188px"}
            sliceNo={5}
            contHeight={"400px"}
           
            paddingMain={"0em 2em 1.5em 2.5em"}
          />

          <Upgrade/>
        
      </section>
    </div>
    )}
    </>
  )
}

export default DiscoverConsumer
