import React from 'react'
import back from '../../../../assets/backicon.svg';
import reportIcon from '../../../../assets/reportIcon2.svg';
import './PageHeader.scss'

const PageHeader = ({reportTitle,pageName}) => {
  return (

    <div className='page-header-cont'>

        <div>
            <img src={back} alt="" />
        </div>

        <div className='pg-divider'>
            
        </div>

        <div className='pg-header-name'>
            <div>
                <img src={reportIcon} alt="icon" />
                <h4>{pageName}</h4>
            </div>

            <div>
                <h5>/</h5>
            </div>
           
            <div>
            <p>{reportTitle}</p>
            </div>
        
            

        </div>
      
    </div>
  )
}

export default PageHeader
