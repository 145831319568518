import React,{useState} from 'react';
import './Upgrade.scss'
import Pricing from '../Pricing/Pricing';

const Upgrade = () => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
 
  return (
    <div>
    <div className='upgrade-main'>
      <div className='upgrade-cont'>
        <div className='upgrade-cont-left'>
            <h4>Upgrade to Unlock More</h4>
            <p>Subscribe to our premium plan to view maps, dashboards, documents and papers.</p>
        </div>
        <div className='upgrade-cont-btn'>
            <button
            onClick={()=>handleOpen()}
            >
            Upgrade Plan
            </button>
        </div>
      </div>
    </div>
   <Pricing
    openModal={openModal} 
    handleClose={handleClose}
   />
 
    </div>
  )
}

export default Upgrade
