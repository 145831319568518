import React from 'react'
import PdfViewer from '../../Reusable/PdfViewer/PdfViewer'

const ViewReport = () => {
  return (
    <div>
      <PdfViewer/>
    </div>
  )
}

export default ViewReport
