import React from 'react'
import ConsumerSidebar from '../../Reusable/ConsumerSidebar/ConsumerSidebar'
import Navbar from '../../Reusable/Navbar/Navbar'
import PageHeader from '../../Reusable/PageHeader/PageHeader'
import { useParams } from 'react-router-dom';
import ReportContentView from '../../Reusable/ReportContentView/ReportContentView';
import SimilarResources from '../../Reusable/SimilarResources/SimilarResources';

const ReportsPage = () => {

  const paramsVal = useParams()

  return (
    <div className='dashboard-main'>
    <ConsumerSidebar/>
    <section>
      <Navbar/>
      <PageHeader
      reportTitle={paramsVal.reportname}
      pageName={"Documents"}
      />
      <ReportContentView
      reportTitle={paramsVal.reportname}
      />

      <SimilarResources
      header={"Similar Resources"}
      />
    </section>
  </div>
  )
}

export default ReportsPage
