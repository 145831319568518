import React,{useEffect, useState} from 'react';
import './Pricing.scss';
import pricingIcon from '../../../../assets/pricingIcon.svg';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import img3 from '../../../../assets/greysclose.svg';
import SyncLoader from "react-spinners/SyncLoader";



const Pricing = ({openModal, handleClose}) => {
    const [openTwo,setOpenTwo]= useState(false);
    const handleCloseTwo =()=>{
        setOpenTwo(false)
    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding:'1em 1em 2em',
        bgcolor: 'background.paper',
        borderRadius: '20px',
        boxShadow: 24,
        display: 'flex',
        width: "auto",
        outline: 'none',
    };
    const styleTwo = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding:'1em 1em 2em',
        bgcolor: 'background.paper',
        borderRadius: '20px',
        boxShadow: 24,
        display: 'flex',
        outline: 'none',
        height:'332px',
        width:"auto",
    
    };

    const handlePayment=()=>{
        handleClose()
        setOpenTwo(true)
    }
    useEffect(() => {
     
    }, [openModal]);
    return (
        <div>
            <Modal
                open={openModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>

                <section className="pricing-container">
                    {/* <button onClick={openModal(false)}>close</button> */}
                    <div className="pricing-body">
                        
                        <div className="right-content">
                            
                                <img
                                    src={img3}
                                    alt="close"
                                    className="closeIcon"
                                    onClick={() => handleClose()}
                                />
                        </div>
                        
                        <div className="pricing-header">
                            <h1>Upgrade your plan for advanced features</h1>
                        </div>

                        <div className="pricing-list">
                            <div className="pricing-list-one-cont">
                                <div className="pricing-list-one">
                                    <h2>$0</h2>
                                    <h6>Free</h6>
                                    <div>
                                        <img src={pricingIcon} alt="icon" />
                                        <p>Dashboard</p>

                                    </div>
                                    <div>
                                        <img src={pricingIcon} alt="icon" />
                                        <p>News</p>
                                    </div>
                                    

                                </div>
                                <h4>Current Plan</h4>
                            </div>

                            <div className="pricing-list-two-cont">                    
                                <div className="pricing-list-two">
                                    <div style={{ marginBottom: "20px" }}>
                                        <h2>$10</h2>
                                        <h5>per month</h5>
                                    </div>
                                    <h6>Basic</h6>
                                    <div>
                                        <img src={pricingIcon} alt="icon" />
                                        <p>Dashboard</p>
                                    </div>
                                    <div>
                                        <img src={pricingIcon} alt="icon" />
                                        <p>News - View and Share </p>
                                    </div>

                                    <div>
                                        <img src={pricingIcon} alt="icon" />
                                        <p>Data - 5 downloads per month </p>
                                    </div>

                                    <div>
                                        <img src={pricingIcon} alt="icon" />
                                        <p>Documents & Papers - 2 downloads per month </p>
                                    </div>

                                    

                                </div>
                                <h4 
                                onClick={()=>handlePayment()}
                                >Try it for 7 days</h4>
                            </div>


                            <div className="pricing-list-three-cont">
                                <div className="pricing-list-three">
                                    <div style={{ marginBottom: "20px" }}>
                                        <h2>$50</h2>
                                        <h5>per month</h5>
                                    </div>
                                    <h6>Premium</h6>
                                    <div>
                                        <img src={pricingIcon} alt="icon" />
                                        <p>Dashboard</p>
                                    </div>
                                    <div>
                                        <img src={pricingIcon} alt="icon" />
                                        <p>News - View and Share </p>
                                    </div>

                                    <div>
                                        <img src={pricingIcon} alt="icon" />
                                        <p>Data - 20 downloads per month </p>
                                    </div>
                                    <div>
                                        <img src={pricingIcon} alt="icon" />
                                        <p>Documents & Papers - 10 downloads per month</p>
                                    </div>
                                    <div>
                                        <img src={pricingIcon} alt="icon" />
                                        <p>Maps & Dashboard - View, Share,
                                        Document Issue</p>
                                    </div>
                                </div>
                                <h4>Try it for 7 days</h4>
                            </div>

                        </div>
                    </div>
                </section>

            
                </Box>
            </Modal>

            <Modal
                open={openTwo}
                onClose={handleCloseTwo}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleTwo}>
                <div className="pricing-body">
                        
                        <div className="right-content">
                            
                                <img
                                    src={img3}
                                    alt="close"
                                    className="closeIcon"
                                    onClick={() => handleCloseTwo()}
                                />
                        </div>
                        
                        <div className="pricing-header">
                            <h1>Redirecting you to our secure payment platform</h1>
                        </div>

                        <div className="loader-box">
                            <SyncLoader 
                            color='#007EFF'
                            />
                        </div>
                   
                </div>

            
                </Box>
            </Modal>
    </div>
       
        
    )
}

export default Pricing
